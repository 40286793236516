import React from "react"
import Layout from "../modules/layout"
import BigBanner from "../modules/m18_bigBanner"
import InnerHero from "../modules/m14_InnerHero"
import LeaveData from "../modules/m21_leaveData"
import { Link } from "gatsby"
import styled from "styled-components"
import { Box, Text } from "@sqymagma/elements"
import m21 from "../data/m21B"
import BlockInner from "../components/blockinner"

const Section = styled(Box)`
  padding: 24px 0px;
`

const Ul = styled.li`
  list-style: square;
  margin: 24px 36px;
`
const Li = styled.li`
  padding: 16px 8px;
  list-style: square;
`

const LegalPage = () => {
  return (
    <Layout title="" desc="" keywords="">
      <InnerHero bc="AVISO LEGAL" title="Ley de protección de datos" url="legal" />
      <BlockInner
        mx="auto"
        mt={["24px", "32px", "32px", "64px", "96px"]}
        mb={["48px", "96px", "96px", "128px", "196px"]}
      >
        <Section>
          <Section>
            <Text textStyle="h2">AVISO LEGAL</Text>
          </Section>
          <Text textStyle="p">
            El presente aviso legal (el “Aviso Legal”) establece los términos y condiciones para
            acceder y utilizar el sitio web alojado bajo el nombre de dominio [Añadir nombre del
            dominio web] y cualquiera de los subdominios dependientes de este (el “Sitio Web”) que
            [Añadir denominación social de la sociedad] (“Living Alternatives”) pone, gratuitamente,
            a disposición de sus usuarios (en adelante, “Usuarios”). El mero acceso al Sitio Web
            implica el conocimiento, comprensión y aceptación, necesariamente y sin reserva alguna,
            de los términos y condiciones estipulados en el presente Aviso Legal. Por lo tanto, se
            recomienda que los Usuarios lean cuidadosamente el presente Aviso Legal, así como los
            mensajes, instrucciones o advertencias que les sean comunicadas mientras navegan o hacen
            uso del Sitio Web, en tanto que deberán cumplir con todos ellos. Si los Usuarios están
            en desacuerdo con estas estipulaciones, no deberán utilizar el Sitio Web. Living
            ALTERNATIVES se reserva el derecho de modificar o actualizar el Aviso Legal en cualquier
            momento y sin previo aviso, por exigencias normativas, por motivos técnicos, por cambios
            en los servicios ofrecidos por Living Alternatives o por decisiones estratégicas de la
            compañía, mediante la modificación o actualización del texto accesible a los Usuarios en
            el presente Aviso Legal, por lo que se recomienda a los Usuarios que periódicamente lo
            revisen. Si el Usuario no está conforme con los cambios, deberá dejar de utilizar el
            Sitio Web y, en su caso, darse de baja de cualquier registro asociado al mismo. La
            utilización del Sitio Web tras la realización de los cambios supondrá la aceptación del
            mismo.{" "}
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Identificación del titular del Sitio Web
            </Text>
          </Section>
          <Text textStyle="p">
            El titular de este Sitio Web es [Añadir denominación social de la sociedad] con CIF
            número [Añadir número CIF de la sociedad], domicilio social en [Añadir domicilio social
            de la sociedad] e inscrita en el Registro Mercantil de [Añadir ciudad del Registro
            Mercantil donde se encuentre inscrita la sociedad], Tomo [Añadir número de tomo], Folio
            [Añadir número de folio], Hoja [Añadir número de hoja]. Correo electrónico: [Añadir
            correo electrónico de la sociedad]
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Objeto del Sitio Web
            </Text>
          </Section>
          <Text textStyle="p">
            El Sitio Web tiene como objetivo principal informar de la prestación de los servicios
            que realiza Living Alternatives (el “Servicio”). Acceso al Sitio Web El acceso, la
            navegación y uso del Sitio Web es gratuito. Algunos de los servicios prestados a través
            del Sitio Web pueden estar regulados por políticas y/o instrucciones especiales que, en
            tales casos, sustituirán, completarán o modificarán el contenido de este Aviso Legal. El
            Usuario debe acceder al Sitio Web conforme a la buena fe, las normas de orden público y
            al presente Aviso Legal. El acceso al Sitio Web se realizará bajo la propia y exclusiva
            responsabilidad del Usuario, que responderán en todo caso de los daños y perjuicios que
            pueda causar a terceros o a Living Alternatives. Los Usuarios se comprometen a hacer un
            uso adecuado y lícito del Sitio Web de conformidad con la legislación aplicable y el
            presente Aviso Legal. Los Usuarios deberán abstenerse de:
          </Text>
          <Ul>
            <Li>
              <Text textStyle="p">Hacer un uso no autorizado o fraudulento del Sitio Web.</Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Acceder o intentar acceder a recursos restringidos del Sitio Web.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Suplantar la identidad de terceros o acosar a otros Usuarios, así como utilizar el
                Sitio Web y sus distintas posibilidades de forma ofensiva, dañina, o inapropiada de
                acuerdo con el Derecho, la buena fe, la moral y el orden público.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Utilizar el Sitio Web con fines ilícitos, ilegales, contrarios a lo establecido en
                el presente Aviso Legal, lesivos de los derechos e intereses de terceros, o que de
                cualquier forma puedan dañar, inutilizar o sobrecargar o impedir la normal
                utilización o disfrute del Sitio Web.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Provocar daños en el Sitio Web o en los sistemas de terceros u otros Usuarios, así
                como, en particular, de introducir o difundir virus informáticos o cualesquiera
                otros sistemas físicos o lógicos que sean susceptibles de provocar daños en los
                sistemas de Living Alternatives, de terceros u otros Usuarios.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Intentar acceder, utilizar y/o manipular los datos de Living Alternatives, terceros
                u otros Usuarios.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Aplicar técnicas de ingeniería inversa, descifrar, descompilar o utilizar cualquier
                otro sistema para conocer el código fuente del Sitio Web o de cualquier otro
                elemento sujeto a copyright o derechos de propiedad intelectual o industrial.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Facilitar el correo electrónico de uno o varios terceros sin su expreso
                consentimiento.
              </Text>
            </Li>
            <Li>
              <Text textStyle="p">
                Modificar o intentar modificar el Sitio Web ni realizar acciones o utilizar medios
                tendentes a alterar su apariencia o sus funciones.
              </Text>
            </Li>
          </Ul>
          <Text textStyle="p">
            El Usuario garantiza la autenticidad y actualidad de todos aquellos datos que comunique
            a Living Alternatives y será el único responsable de las manifestaciones falsas o
            inexactas que realice.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Limitación de responsabilidad Living Alternatives
            </Text>
          </Section>
          <Text textStyle="p">
            Living Alternatives no garantiza la disponibilidad y continuidad del funcionamiento del
            Sitio Web. En consecuencia, Living Alternatives no será en ningún caso responsable por
            cualesquiera daños y perjuicios que puedan derivarse de: La falta de disponibilidad o
            accesibilidad al Sitio Web; La interrupción en el funcionamiento del Sitio Web o fallos
            informáticos, averías telefónicas, desconexiones, retrasos o bloqueos causados por
            deficiencias o sobrecargas en las líneas telefónicas, centros de datos, en el sistema de
            Internet o en otros sistemas electrónicos, producidos en el curso de su funcionamiento;
            Errores o retrasos en el acceso al Sitio Web por parte del Usuario a la hora de
            introducir sus datos en el formulario de contacto, la lentitud o cualquier anomalía que
            pueda surgir cuando estas incidencias sean debidas a problemas en la red de internet,
            causas de caso fortuito o fuerza mayor y cualquier otra contingencia imprevisible ajena
            a la buena fe de Living Alternatives; Las pérdidas, daños o perjuicios de cualquier tipo
            que surjan por acceder y hacer uso del Sitio Web, incluyéndose, pero no limitándose a
            los producidos en los sistemas informáticos o los provocados por la introducción de
            virus y/o ataques informáticos; La veracidad, integridad o actualización de las
            informaciones que no sean de elaboración propia; Las diferencias o inexactitudes que
            puedan existir en el Sitio Web debido a la falta de resolución de la pantalla del
            dispositivo móvil o problemas del navegador que se utilice u otros de esta índole;
            Aquellos fallos técnicos que, por causas fortuitas o de otra índole, impidan un normal
            funcionamiento del servicio a través de internet. Falta de disponibilidad del Sitio Web
            por razones de mantenimiento u otras, que impida disponer del Servicio; y/o Otros daños
            que puedan ser causados por terceros mediante intromisiones no autorizadas ajenas al
            control del Sitio Web. Living Alternatives no garantiza la ausencia de virus ni de otros
            elementos en el Sitio Web introducidos por terceros ajenos a este que puedan producir
            alteraciones en los sistemas físicos o lógicos del Usuario o en los documentos
            electrónicos y ficheros almacenados en sus sistemas. En consecuencia, Living
            ALTERNATIVES no será, en ningún caso, responsable de cualesquiera daños y perjuicios de
            toda naturaleza que pudieran derivarse de la presencia de virus u otros elementos que
            puedan producir alteraciones en los sistemas físicos o lógicos, documentos electrónicos
            o ficheros del Usuario. Living Alternatives no garantiza que terceros no autorizados no
            puedan conocer las condiciones, características y circunstancias en las cuales los
            Usuarios acceden al Sitio Web. En consecuencia, Living Alternatives no será en ningún
            caso responsable de los daños y perjuicios que pudieran derivarse de dicho acceso no
            autorizado. Con la suscripción del presente Aviso Legal, los Usuarios declaran que
            mantendrán indemne a Living Alternatives frente a cualquier reclamación contra la misma,
            su sociedad matriz, directores, socios, empleados, abogados y agentes derivada del
            incumplimiento por parte de los Usuarios de cualquier disposición contenida en el
            presente Aviso Legal o de cualquier ley o regulación aplicable al mismo del
            incumplimiento o violación de los derechos de terceros. En todo caso, Living
            ALTERNATIVES sólo responderá de aquellos daños que los Usuarios pudieran sufrir como
            consecuencia de una actuación dolosa, o manifiestamente negligente por parte de esta, en
            relación con el acceso al Sitio Web, la prestación de sus servicios, así como el uso de
            los contenidos, herramientas y funcionalidades del Sitio Web.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4" my="24px">
              Enlaces a terceros
            </Text>
          </Section>
          <Text textStyle="p">
            Living Alternatives no controla el contenido de otros sitios a los que se pueda acceder
            desde el Sitio Web; o a los que éste permita acceder a través de diferentes enlaces
            (links). Por tanto, Living Alternatives no se hace responsable ni de la información
            contenida en los mismos ni de cualesquiera efectos que pudieran derivarse de dicha
            información. Living Alternatives pone tales enlaces a disposición de los Usuarios, para
            la comodidad y conveniencia de éstos, acción que en ningún caso podrá ser interpretada
            como la aprobación o el patrocinio, por parte de Living Alternatives, de los contenidos
            de dichos sitios web. Living Alternatives no ofrece ninguna garantía, expresa o
            implícita, sobre la veracidad, propiedad, validez o legalidad de cualquiera de los
            sitios enlazados y su contenido. Si cualquier Usuario o un tercero observara que los
            contenidos a los que este Sitio Web permite acceder a través de enlaces pueden ser
            contrarios a las leyes, la moral o el orden público, deberá informar a Living
            ALTERNATIVES correo electrónico: [Añadir correo electrónico de la sociedad].
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Indemnización</Text>
          </Section>

          <Text textStyle="p">
            En caso de que Living Alternatives, las empresas de su grupo o cualquier otra de las
            personas involucradas en la creación, producción y distribución de este Sitio Web
            sufriera cualquier tipo de daños, perjuicios, pérdidas o costes (incluidos los
            honorarios de abogados y procuradores que hubieran sido necesarios) como consecuencia de
            un incumplimiento por parte de los Usuarios de este Aviso Legal, los Usuarios en
            cuestión vendrán obligados a resarcir en su totalidad los daños causados. Lo establecido
            anteriormente será de aplicación, en particular, pero sin quedar limitado a, los casos
            en que, como consecuencia de los incumplimientos de los Usuarios, se produjeran
            reclamaciones por parte de terceros contra Living Alternatives o cualesquiera de las
            empresas de su grupo. Los Usuarios mantendrán indemne a Living Alternatives y a las
            empresas de su grupo, quienes podrán reclamarle cualquier gasto, coste, daño o perjuicio
            que se hubiera derivado de sus acciones, siempre y cuando este gasto, coste, daño o
            perjuicio traiga causa del incumplimiento del presente Aviso Legal por parte del
            Usuario.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Derechos de propiedad intelectual e industrial </Text>
          </Section>

          <Text textStyle="p">
            Este Sitio Web pertenece a Living Alternatives. Sus contenidos (incluyendo, con carácter
            meramente enunciativo su código fuente, marcas comerciales, imágenes, iconos, diseños, y
            presentación general) están protegidos, de acuerdo con la normativa española,
            comunitaria e internacional, por derechos de autor, marcas registradas y otros derechos
            de propiedad intelectual e industrial titularidad de Living Alternatives, de sociedades
            de su grupo o de terceros. El acceso a este Sitio Web no confiere en ningún caso, ni
            podrá ser interpretado como autorización o licencia de ningún tipo sobre tales
            contenidos y derechos. Asimismo, ciertos nombres comerciales, marcas, logotipos,
            eslóganes, dibujos, diseños de moda y otros materiales expuestos en este Sitio Web
            pertenecen y/o están debidamente registrados a nombre de Living Alternatives o de otras
            entidades. Los Usuarios no están autorizados para utilizar ninguno de dichos elementos,
            cuya propiedad permanecerá, en todo caso, bajo titularidad de Living Alternatives. Queda
            expresamente prohibido cualquier acto de reproducción, distribución, transformación o
            comunicación pública, así como cualquier tipo de cesión, de todo o parte del contenido
            del Sitio Web y/o, en general, cualquier acto de explotación de la totalidad o parte de
            los contenidos (imágenes, textos, diseño, índices, formas, etc.) así como de las
            posibles bases de datos que Living Alternatives pudiera contener y de cualquier objeto,
            que sea protegible según la legislación vigente, especialmente conforme a la normativa
            de propiedad intelectual e industrial. Los Usuarios podrán, ocasionalmente, imprimir
            copias de los modelos, formularios o documentos del Sitio Web, siempre que lo hagan para
            su uso personal, no comercial. Living Alternatives se reserva, expresamente, cuantas
            acciones civiles y penales, en virtud de la legislación española y extranjera pudieran
            corresponderle como consecuencia de la vulneración de la pacífica posesión y/o
            utilización no autorizada de sus derechos de propiedad industrial e intelectual.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Protección de datos</Text>
          </Section>

          <Text textStyle="p">
            La navegación y el uso de ciertos Servicios en este Sitio Web, puede requerir que los
            Usuarios suministren datos de carácter personal. Living Alternatives procesa este tipo
            de información cumpliendo con la legislación aplicable, conforme a su{" "}
            <Link to="/privacy">Política de Privacidad </Link> que forma parte del presente Aviso
            Legal. Política de cookies
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Política de cookies </Text>
          </Section>

          <Text textStyle="p">
            Durante la navegación por el Sitio Web se empleará la utilización de cookies, cuyo uso
            estará regulado, de conformidad con lo dispuesto en la normativa aplicable, y a lo
            señalado en la Política de <Link to="/cookies">Cookies del Sitio Web</Link> que, a su
            vez, forma parte de la Política de Privacidad. A este respecto, informamos de que las
            cookies son pequeños ficheros de datos que se alojan en el terminal del Usuario
            visitante del Sitio Web y que contienen o almacenan cierta información de la visita al
            mismo.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Notificaciones</Text>
          </Section>
          <Text textStyle="p">
            Living Alternatives podrá realizar las notificaciones oportunas a través de la dirección
            de correo electrónico facilitada por los usuarios en el formulario de contacto.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Cesión</Text>
          </Section>
          <Text textStyle="p">
            Los Usuarios no podrán ceder sus derechos y obligaciones conforme al presente Aviso
            Legal sin el previo consentimiento escrito de Living Alternatives. Asimismo, Living
            ALTERNATIVES podrá ceder a cualquier entidad comprendida dentro de su grupo de
            sociedades, en todo el mundo, así como a cualquier persona o entidad que le suceda en el
            ejercicio de su negocio por cualesquiera títulos.{" "}
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Renuncia</Text>
          </Section>
          <Text textStyle="p">
            {" "}
            Ninguna renuncia de Living Alternatives a un derecho o acción legal concreta o la falta
            de requerimiento por Living Alternatives del cumplimiento estricto por el Usuario de
            alguna de sus obligaciones supondrá, ni una renuncia a otros derechos o acciones
            derivados de un contrato o del presente Aviso Legal, ni exonerará al Usuario del
            cumplimiento de sus obligaciones. Ninguna renuncia de Living Alternatives a alguna del
            presente Aviso Legal o a los derechos o acciones derivados de un contrato surtirá
            efecto, a no ser que se establezca expresamente que es una renuncia y se formalice y se
            le comunique al Usuario por escrito.{" "}
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Salvaguarda e interpretación</Text>
          </Section>
          <Text textStyle="p">
            Este presente Aviso Legal constituye un acuerdo entre cada uno de los Usuarios y Living
            ALTERNATIVES. Si la autoridad competente declara alguna disposición como ilegal,
            inválida o no ejecutable, la misma deberá ser interpretada de la manera más próxima a la
            intención original de tal disposición. Tal declaración respecto de una o varias
            cláusulas no perjudicará la validez de las restantes. El hecho de que Living
            ALTERNATIVES no exija el cumplimiento estricto de alguna de las estipulaciones contenido
            en este Aviso Legal no constituye ni podrá interpretarse en ningún caso como una
            renuncia por su parte a exigir su estricto cumplimiento en el futuro.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Idioma</Text>
          </Section>
          <Text textStyle="p">
            El idioma aplicable a este Aviso Legal es el español. Por ello, los Usuarios aceptan
            expresamente que las mismas se rijan por su versión en español. Si se ofrece una versión
            en otro idioma es por mera cortesía, para la comodidad de los Usuarios.
          </Text>
        </Section>
        <Section>
          <Section>
            <Text textStyle="h4">Legislación aplicable y jurisdicción</Text>
          </Section>
          <Text textStyle="p">
            Salvo en los casos en que la normativa vigente de aplicación obligue a someterse a una
            legislación distinta, este Sitio Web, sus contenidos y servicios, las relaciones entre
            los Usuarios y Living Alternatives, así como este Aviso Legal se regirán y deberán ser
            interpretados conforme a la ley española. Salvo en los casos en que la normativa vigente
            de aplicación obligue a someterse a un fuero distinto, como el relativo al de los
            juzgados y tribunales del domicilio del Usuario, que será estrictamente respetado en
            caso de resultar imperativo; cualquier cuestión o controversia derivada o relativa a
            este Sitio Web, sus contenidos y servicios, las relaciones entre los Usuarios y Living
            ALTERNATIVES, así como este Aviso Legal se someterá a los jueces y tribunales de Madrid
            capital.
          </Text>
        </Section>
      </BlockInner>
      <LeaveData data={m21} />
      <BigBanner cta="Ver casos de éxito">
        100% de éxito en el cambio de uso de locales comerciales a vivienda
      </BigBanner>
    </Layout>
  )
}

export default LegalPage
